import Vue from 'vue'

class Cities {
  async suggest() {
    return await Vue.axios
      .get('web/cities/suggest')
  }
}

const cities = new Cities()

export default cities
