import Vue from 'vue'

import VueRouter from 'vue-router'

import Maps from '@/views/Maps'
import Error from '@/views/Error'
import Map from '@/views/Map'
import Records from '@/views/Records'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/mapy'
  },
  {
    path: '*',
    redirect: '/chyba'
  },
  {
    path: '/mapy',
    component: Maps
  },
  {
    path: '/chyba',
    component: Error
  },
  {
    path: '/mapa/:slug',
    component: Map
  },
  {
    path: '/zaznamy',
    component: Records
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
