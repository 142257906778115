import Vue from 'vue'

class Years {
  async all() {
    return await Vue.axios
      .get('web/years/all')
  }

  async suggest() {
    return await Vue.axios
      .get('web/years/suggest')
  }

  async read({slug}) {
    return await Vue.axios
      .get('web/years/' + slug)
  }
}

const years = new Years()

export default years
