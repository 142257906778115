<template>
  <v-app
      v-if="content"
  >
    <v-app-bar
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-app-bar"
    >
      <v-app-bar-nav-icon
          @click="$store.dispatch('layout/toggleDrawer')"
      />

      <v-app-bar-title>
        <v-img
            :src="logo"
            max-width="200"
        />
      </v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer
        v-model="$store.state.layout.drawer"
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-navigation-drawer"
    >
      <v-list
          class="custom-list-menu"
      >
        <template
            v-for="(item, i) in items"
        >
          <v-list-item
              v-if="!item.subitems"
              :key="i"
              :to=item.to
              :ripple="false"
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
              v-else
              :key="i"
              :value="item.value"
              :ripple="false"
          >
            <template
                v-slot:activator
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template
                v-for="(subitem, j) in item.subitems"
            >
              <v-list-item
                  :key="j"
                  :to=subitem.to
                  :ripple="false"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subitem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container
          :fluid="true"
      >
        <v-breadcrumbs
            v-if="$store.state.layout.breadcrumbs.length > 0"
            divider="/"
            :items="$store.state.layout.breadcrumbs"
        />

        <router-view/>
      </v-container>
    </v-main>

    <v-snackbar
        v-model="$store.state.layout.snackbar"
        :app="true"
        :color="$store.state.layout.message.color"
    >
      {{ $store.state.layout.message.content }}
    </v-snackbar>

    <v-footer
        :app="true"
        :dark="true"
        class="custom-footer"
    >
      @ 2023

      <v-spacer/>

      v{{ version() }}

      <v-spacer/>

      <a
          href="https://fuudobre.sk/"
          target="_blank"
      >
        <v-img
            src="https://api.fuudobre.sk/assets/img/copyright_white.svg"
            alt="FúúDobre"
        />
      </a>
    </v-footer>
  </v-app>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import logo from '@/assets/img/logo.svg'

  import menu from '@/plugins/config/menu'

  export default {
    name: 'App',
    data() {
      return {
        content: false,
        loading: false,
        logo: logo
      }
    },
    metaInfo() {
      return {
        titleTemplate: 'SčítacieHárky.sk | %s',
        meta: [
          {
            charset: 'utf-8'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          },
          {
            name: 'description',
            content: 'Hľadáš informácie o svojich predkoch? Vytvorili sme aplikáciu, ktorá pomôže pri vyhľadávaní v sčítacích hárkoch z rokov 1930 a 1940.'
          }
        ]
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      items() {
        return menu.items.all
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        this.$store.dispatch('register/callIndex')
          .finally(() => {
            this[key] = !value
          })
      },
      version() {
        return process.env.VUE_APP_VERSION
      }
    }
  }
</script>
