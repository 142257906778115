<template>
  <v-col
      cols="12"
  >
    <v-card
        :id="'record_' + Record.id"
    >
      <v-card-title>
        <a
            :href="Record.url"
            target="_blank"
            tabindex="-1"
        >
          {{ Record.title }}
        </a>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
              cols="12"
              lg="4"
          >
            <v-img
                :src="loadLinkByPath('web/records/' + Record.id + '/file/jpeg/' + number)"
                :alt="Record.title"
            />
          </v-col>

          <v-col
              cols="12"
              lg="8"
          >
            <v-row>
              <v-col
                  cols="12"
                  order="1"
                  order-lg="2"
              >
                <v-row>
                  <template
                      v-for="(idJpeg, i) in Record.countContentJpeg"
                  >
                    <v-col
                        v-if="idJpeg !== number"
                        :key="i"
                        cols="4"
                    >
                      <a
                          @click="setNumber(idJpeg)"
                      >
                        <v-img
                            :src="loadLinkByPath('web/records/' + Record.id + '/file/jpeg/' + idJpeg)"
                            :alt="Record.title"
                        />
                      </a>
                    </v-col>
                  </template>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
                  order="2"
                  order-lg="1"
              >
                <v-row>
                  <v-col
                      cols="12"
                      lg="8"
                  >
                    <v-text-field
                        v-model="RecordForm.families"
                        :error-messages="errors.families"
                        :outlined="true"
                        :dense="true"
                        label="Priezviská"
                        :persistent-hint="true"
                        hint="V prípade, že nevieš tak: ?"
                        @focus="scroll()"
                        @change="submit()"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="4"
                  >
                    <v-select
                        v-model="RecordForm.type"
                        :error-messages="errors.type"
                        :outlined="true"
                        :dense="true"
                        label="Typ"
                        :items="$store.getters['register/getRecordType']"
                        @change="submit()"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  import Records from '@/services/records'

  export default {
    name: 'Record',
    props: [
      'Record'
    ],
    data() {
      return {
        loading: false,
        number: 1,
        errors: {}
      }
    },
    computed: {
      id() {
        return this.Record.id
      },
      RecordForm() {
        return {
          families: this.Record.families,
          type: this.Record.type
        }
      }
    },
    methods: {
      setNumber(number) {
        this.number = number
      },
      scroll() {
        window.scrollTo(0, document.getElementById('record_' + this.Record.id).offsetTop)
      },
      submit() {
        this.loading = true

        Records
          .update({
            id: this.id,
            data: this.RecordForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 404:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
