<template>
  <v-card
      v-if="content"
  >
    <v-card-title>
      {{ Year.title }}
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <Maps
          :Year="Year"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import Maps from '@/components/Map/Maps'

  import Years from '@/services/years'

  export default {
    name: 'Map',
    components: {
      Maps
    },
    data() {
      return {
        content: false,
        loading: false,
        Year: {}
      }
    },
    metaInfo() {
      return {
        title: this.Year.title
      }
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Mapy',
              to: '/mapy'
            }
          ]
        })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        this.setBreadcrumbs()

        Years
          .read({
            slug: this.$route.params.slug
          })
          .then((response) => {
            this.Year = response.data.data.Year

            this.$store.dispatch('layout/addBreadcrumbs', {
              breadcrumbs: [
                {
                  text: this.Year.title,
                  to: '/mapa/' + this.Year.slug
                }
              ]
            })
          })
          .catch((error) => {
            switch (error.response.status) {
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/maps')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
