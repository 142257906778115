<template>
  <v-card>
    <v-card-title>
      Záznamy ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init()"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
            lg="3"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <SuggestYears
                    v-model="params.Filters.Year"
                    :error-messages="errors.Filters && errors.Filters.Year"
                    :outlined="true"
                    :dense="true"
                    label="Rok"
                    :clearable="true"
                    @change="setFilter()"
                />

                <SuggestCountries
                    v-model="params.Filters.Country"
                    :error-messages="errors.Filters && errors.Filters.Country"
                    :outlined="true"
                    :dense="true"
                    label="Krajina"
                    :clearable="true"
                    @change="setFilter()"
                />

                <SuggestDistricts
                    v-model="params.Filters.District"
                    :error-messages="errors.Filters && errors.Filters.District"
                    :outlined="true"
                    :dense="true"
                    label="Okres"
                    :clearable="true"
                    @change="setFilter()"
                />

                <SuggestCities
                    v-model="params.Filters.City"
                    :error-messages="errors.Filters && errors.Filters.City"
                    :outlined="true"
                    :dense="true"
                    label="Mesto"
                    :clearable="true"
                    @change="setFilter()"
                />

                <v-text-field
                    v-model="params.Filters.title"
                    :error-messages="errors.Filters && errors.Filters.title"
                    :outlined="true"
                    :dense="true"
                    label="Názov"
                    :clearable="true"
                    @change="setFilter()"
                />

                <v-text-field
                    v-model="params.Filters.family"
                    :error-messages="errors.Filters && errors.Filters.family"
                    :outlined="true"
                    :dense="true"
                    label="Priezvisko"
                    :clearable="true"
                    @change="setFilter()"
                />

                <v-select
                    v-model="params.Filters.type"
                    :error-messages="errors.Filters && errors.Filters.type"
                    :outlined="true"
                    :dense="true"
                    label="Typ"
                    :clearable="true"
                    :items="$store.getters['register/getRecordType']"
                    @change="setFilter()"
                />

                <v-select
                    v-model="params.Filters.format"
                    :error-messages="errors.Filters && errors.Filters.format"
                    :outlined="true"
                    :dense="true"
                    label="Formát"
                    :clearable="true"
                    :items="$store.getters['register/getRecordFormat']"
                    @change="setFilter()"
                />

                <v-select
                    v-model="params.Filters.status"
                    :error-messages="errors.Filters && errors.Filters.status"
                    :outlined="true"
                    :dense="true"
                    label="Stav"
                    :clearable="true"
                    :items="$store.getters['register/getRecordStatus']"
                    @change="setFilter()"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col
            cols="12"
            lg="9"
        >
          <v-progress-linear
              v-if="loading"
              :indeterminate="true"
          />

          <v-row
              v-if="records.length > 0"
          >
            <v-col
                cols="12"
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-expansion-panels
                      v-model="expansionPanelsFaq"
                      :multiple="true"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span>
                          <v-icon
                              color="error"
                          >
                            mdi-alert
                          </v-icon>

                          Pravidlá
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <p>V prípade, že sčítací hárok je</p>

                        <ol>
                          <li>
                            <code><strong>prázdny</strong></code> zadaj pomlčku <code>-</code>,
                          </li>

                          <li>
                            <code><strong>chybný</strong></code> zadaj výkričník <code>!</code>,
                          </li>

                          <li>
                            <code><strong>typu</strong></code>

                            <ul>
                              <li>
                                <code><strong>Popisný hárok</strong></code> zadaj priezviská v mužskom rode oddelené čiarkou a mezerou napr. <code><strong>Kováč, Weiss</strong></code>,
                              </li>

                              <li>
                                <code><strong>Prehľad sčítacieho obvodu</strong></code> zadaj obvod, ulice a rozsah popisných čísel napr. <code><strong>1.obvod, Hlavná 1-10</strong></code>,
                              </li>

                              <li>
                                <code><strong>Sčítací lístok pre hosťov</strong></code> zadaj priezvisko v mužskom rode napr. <code><strong>Kováč alebo Weiss</strong></code>.
                              </li>
                            </ul>
                          </li>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-row>
                <Record
                    v-for="Record in records"
                    :key="Record.id"
                    :Record="Record"
                />
              </v-row>
            </v-col>
          </v-row>

          <v-alert
              v-else
              type="error"
          >
            Nenašli sa žiadne záznamy.
          </v-alert>

          <v-row
              align="center"
          >
            <v-col
                cols="4"
            >
              <a
                  v-for="(limit, i) in $store.getters['register/getRecordFilterLimit']"
                  :key="i"
                  @click="setLimit(limit.value)"
              >
                {{ limit.text }}
              </a>
            </v-col>

            <v-spacer/>

            <v-col
                cols="auto"
            >
              <v-btn
                  :icon="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="params.page === 1 || loading"
                  color="primary"
                  @click="previousPage()"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>

            <v-col
                cols="auto"
            >
              {{ params.page }} / {{ pages }}
            </v-col>

            <v-col
                cols="auto"
            >
              <v-btn
                  :icon="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="params.page === pages || loading"
                  color="primary"
                  @click="nextPage()"
              >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import Records from '@/services/records'

  import SuggestYears from '@/components/Years/Suggest'
  import SuggestCountries from '@/components/Countries/Suggest'
  import SuggestDistricts from '@/components/Districts/Suggest'
  import SuggestCities from '@/components/Cities/Suggest'

  import Record from '@/components/Records/Record'

  export default {
    name: 'Records',
    components: {
      SuggestYears,
      SuggestCountries,
      SuggestDistricts,
      SuggestCities,
      Record
    },
    data() {
      return {
        expansionPanelsFilter: [
          0
        ],
        expansionPanelsFaq: [
          0
        ],
        loading: false,
        count: 0,
        pages: 1,
        records: [],
        params: {
          Filters: {
            Year: '',
            Country: '',
            District: '',
            City: '',
            title: '',
            family: '',
            type: '',
            format: '',
            status: ''
          },
          order: this.$store.state.register.Record.Filter.order['title'].value,
          by: this.$store.state.register.Record.Filter.by['ASC'].value,
          limit: this.$store.state.register.Record.Filter.limit[100].value,
          page: 1
        },
        errors: {}
      }
    },
    metaInfo() {
      return {
        title: 'Záznamy'
      }
    },
    beforeMount() {
      this.params = {
        ...this.params,
        ...Qs.parse(QueryTypes.parseObject(this.$route.query))
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Záznamy',
              to: '/zaznamy'
            }
          ]
        })
      },
      setFilter() {
        this.params.page = 1

        this.loadData()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.Record.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.Record.Filter.by['DESC'].value

            break

          case this.$store.state.register.Record.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.Record.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.loadData()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.loadData()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.loadData()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.loadData()
      },
      loadData() {
        this.loading = true

        this.setBreadcrumbs()

        this.$router.replace('?' + Qs.stringify(this.params))

        Records
          .index({
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.records = response.data.data.records

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
