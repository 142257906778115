import Register from '@/services/register'

const state = {
  Record: {
    type: {},
    format: {},
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  }
}

const mutations = {
  SET_RECORD(state, Record) {
    state.Record = Record
  }
}

const actions = {
  async callIndex({commit}) {
    return await Register
      .index()
      .then((response) => {
        commit('SET_RECORD', response.data.data.Record)
      })
      .catch(() => {
        commit('SET_RECORD', {
          type: {},
          format: {},
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })
      })
  }
}

const getters = {
  getRecordType(state) {
    return Object.values(state.Record.type)
  },
  getRecordFormat(state) {
    return Object.values(state.Record.format)
  },
  getRecordStatus(state) {
    return Object.values(state.Record.status)
  },
  getRecordFilterOrder(state) {
    return Object.values(state.Record.Filter.order)
  },
  getRecordFilterBy(state) {
    return Object.values(state.Record.Filter.by)
  },
  getRecordFilterLimit(state) {
    return Object.values(state.Record.Filter.limit)
  }
}

const register = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default register
