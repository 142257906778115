var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" Mapy "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":true,"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"default"},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),(_vm.years.length > 0)?_c('v-row',_vm._l((_vm.years),function(Year){return _c('v-col',{key:Year.id,attrs:{"cols":"12","lg":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(Year.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":true,"outlined":true,"color":"primary","to":'/mapa/' + Year.slug}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1),_c('v-card-text',[_c('l-map',{style:(Year.style),attrs:{"crs":_vm.crs,"zoom":Year.zoom,"center":Year.center,"min-zoom":Year.minZoom,"max-zoom":Year.maxZoom}},[_c('l-image-overlay',{attrs:{"url":_vm.loadLinkByPath('web/files/generate/original/' + Year.File.token),"bounds":Year.bounds}}),_vm._l((Year.countryStatistics),function(CountryStatistic){return _c('l-marker',{key:CountryStatistic.countryId,attrs:{"latLng":{
                    lat: CountryStatistic.lat,
                    lng: CountryStatistic.lng
                  }}},[_c('l-icon',{attrs:{"icon-url":(
                        CountryStatistic.balanceIndexed > 80
                          ? _vm.iconUrlGreen
                          : (
                            CountryStatistic.balanceIndexed < 20
                              ? _vm.iconUrlRed
                              : _vm.iconUrlOrange
                          )
                    )}}),_c('l-popup',[_c('h3',[_vm._v(_vm._s(CountryStatistic.title)+" ("+_vm._s(CountryStatistic.balanceIndexed)+" %)")]),_c('br'),_c('ul',[_c('li',[_vm._v(" Indexovaných: "),_c('b',[_vm._v(_vm._s(CountryStatistic.indexed))])]),_c('li',[_vm._v(" Neindexovaných: "),_c('b',[_vm._v(_vm._s(CountryStatistic.unindexed))])]),_c('li',[_vm._v(" Celkovo: "),_c('b',[_vm._v(_vm._s(CountryStatistic.total))])])]),_c('br'),_c('router-link',{attrs:{"to":'/zaznamy?Filters[Year]=' + Year.id + '&Filters[Country]=' + CountryStatistic.id}},[_vm._v(" zobraziť viac ")])],1)],1)})],2)],1)],1)],1)}),1):_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Nenašli sa žiadne mapy. ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }