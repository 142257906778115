var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" Mapy "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":true,"outlined":true,"loading":_vm.loading,"disabled":_vm.loading,"color":"default"},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)],1),_c('v-card-text',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),(_vm.countries.length > 0)?_c('v-row',_vm._l((_vm.countries),function(Country){return _c('v-col',{key:Country.id,attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(Country.title)+" ")]),_c('v-card-text',[_c('l-map',{style:(Country.style),attrs:{"crs":_vm.crs,"zoom":Country.zoom,"center":Country.center,"min-zoom":Country.minZoom,"max-zoom":Country.maxZoom}},[_c('l-image-overlay',{attrs:{"url":_vm.loadLinkByPath('web/files/generate/original/' + Country.File.token),"bounds":Country.bounds}}),_vm._l((Country.countryDistrictStatistics),function(CountryDistrictStatistic){return _c('l-marker',{key:CountryDistrictStatistic.districtId,attrs:{"latLng":{
                    lat: CountryDistrictStatistic.lat,
                    lng: CountryDistrictStatistic.lng
                  }}},[_c('l-icon',{attrs:{"icon-url":(
                        CountryDistrictStatistic.balanceIndexed > 80
                          ? _vm.iconUrlGreen
                          : (
                            CountryDistrictStatistic.balanceIndexed < 20
                              ? _vm.iconUrlRed
                              : _vm.iconUrlOrange
                          )
                    )}}),_c('l-popup',[_c('h3',[_vm._v(_vm._s(CountryDistrictStatistic.title)+" ("+_vm._s(CountryDistrictStatistic.balanceIndexed)+" %)")]),_c('br'),_c('ul',[_c('li',[_vm._v(" Indexovaných: "),_c('b',[_vm._v(_vm._s(CountryDistrictStatistic.indexed))])]),_c('li',[_vm._v(" Neindexovaných: "),_c('b',[_vm._v(_vm._s(CountryDistrictStatistic.unindexed))])]),_c('li',[_vm._v(" Celkovo: "),_c('b',[_vm._v(_vm._s(CountryDistrictStatistic.total))])])]),_c('br'),_c('router-link',{attrs:{"to":'/zaznamy?Filters[Year]=' + _vm.Year.id + '&Filters[Country]=' + Country.id + '&Filters[District]=' + CountryDistrictStatistic.districtId}},[_vm._v(" zobraziť viac ")])],1)],1)})],2)],1)],1)],1)}),1):_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Nenašli sa žiadne mapy. ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }