import Vue from 'vue'

class Countries {
  async allYear({yearId}) {
    return await Vue.axios
      .get('web/countries/all-by-year/' + yearId)
  }

  async suggest() {
    return await Vue.axios
      .get('web/countries/suggest')
  }
}

const countries = new Countries()

export default countries
