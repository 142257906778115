<template>
  <v-card>
    <v-card-title>
      Mapy

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init()"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-row
          v-if="countries.length > 0"
      >
        <v-col
            v-for="Country in countries"
            :key="Country.id"
            cols="12"
        >
          <v-card>
            <v-card-title>
              {{ Country.title }}
            </v-card-title>

            <v-card-text>
              <l-map
                  :crs="crs"
                  :zoom="Country.zoom"
                  :center="Country.center"
                  :min-zoom="Country.minZoom"
                  :max-zoom="Country.maxZoom"
                  :style="Country.style"
              >
                <l-image-overlay
                    :url="loadLinkByPath('web/files/generate/original/' + Country.File.token)"
                    :bounds="Country.bounds"
                />

                <l-marker
                    v-for="CountryDistrictStatistic in Country.countryDistrictStatistics"
                    :key="CountryDistrictStatistic.districtId"
                    :latLng="{
                      lat: CountryDistrictStatistic.lat,
                      lng: CountryDistrictStatistic.lng
                    }"
                >
                  <l-icon
                      :icon-url="(
                          CountryDistrictStatistic.balanceIndexed > 80
                            ? iconUrlGreen
                            : (
                              CountryDistrictStatistic.balanceIndexed < 20
                                ? iconUrlRed
                                : iconUrlOrange
                            )
                      )"
                  />

                  <l-popup>
                    <h3>{{ CountryDistrictStatistic.title }} ({{ CountryDistrictStatistic.balanceIndexed }} %)</h3>

                    <br/>

                    <ul>
                      <li>
                        Indexovaných: <b>{{ CountryDistrictStatistic.indexed }}</b>
                      </li>
                      <li>
                        Neindexovaných: <b>{{ CountryDistrictStatistic.unindexed }}</b>
                      </li>
                      <li>
                        Celkovo: <b>{{ CountryDistrictStatistic.total }}</b>
                      </li>
                    </ul>

                    <br/>

                    <router-link
                        :to="'/zaznamy?Filters[Year]=' + Year.id + '&Filters[Country]=' + Country.id + '&Filters[District]=' + CountryDistrictStatistic.districtId"
                    >
                      zobraziť viac
                    </router-link>
                  </l-popup>
                </l-marker>
              </l-map>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-alert
          v-else
          type="error"
      >
        Nenašli sa žiadne mapy.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import { CRS } from 'leaflet'
  import { LIcon, LMap, LImageOverlay, LMarker, LPopup } from 'vue2-leaflet'

  import Countries from '@/services/countries'

  import markerRed from '@/assets/img/markers/red.svg'
  import markerOrange from '@/assets/img/markers/orange.svg'
  import markerGreen from '@/assets/img/markers/green.svg'

  export default {
    name: 'Maps',
    props: [
      'Year'
    ],
    components: {
      LIcon,
      LMap,
      LImageOverlay,
      LMarker,
      LPopup
    },
    data() {
      return {
        loading: false,
        countries: [],
        crs: CRS.Simple,
        iconUrlRed: markerRed,
        iconUrlOrange: markerOrange,
        iconUrlGreen: markerGreen
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        Countries
          .allYear({
            yearId: this.Year.id
          })
          .then((response) => {
            this.countries = response.data.data.countries
          })
          .catch((error) => {
            switch (error.response.status) {
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
