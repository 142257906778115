const all = [
  {
    to: '/mapy',
    title: 'Mapy',
    icon: 'mdi-map'
  },
  {
    to: '/zaznamy',
    title: 'Záznamy',
    icon: 'mdi-file'
  }
]

const items = {
  all
}

const menu = {
  items
}

export default menu
