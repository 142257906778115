import Vue from 'vue'

import Qs from 'qs'

class Records {
  async index({params}) {
    return await Vue.axios
      .get('web/records', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async update({id, data}) {
    return await Vue.axios
      .put('web/records/' + id, data)
  }
}

const records = new Records()

export default records
