<template>
  <v-card>
    <v-card-title>
      Mapy

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init()"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-progress-linear
          v-if="loading"
          :indeterminate="true"
      />

      <v-row
          v-if="years.length > 0"
      >
        <v-col
            v-for="Year in years"
            :key="Year.id"
            cols="12"
            lg="6"
        >
          <v-card>
            <v-card-title>
              {{ Year.title }}

              <v-spacer/>

              <v-btn
                  :icon="true"
                  :outlined="true"
                  color="primary"
                  :to="'/mapa/' + Year.slug"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <l-map
                  :crs="crs"
                  :zoom="Year.zoom"
                  :center="Year.center"
                  :min-zoom="Year.minZoom"
                  :max-zoom="Year.maxZoom"
                  :style="Year.style"
              >
                <l-image-overlay
                    :url="loadLinkByPath('web/files/generate/original/' + Year.File.token)"
                    :bounds="Year.bounds"
                />

                <l-marker
                    v-for="CountryStatistic in Year.countryStatistics"
                    :key="CountryStatistic.countryId"
                    :latLng="{
                      lat: CountryStatistic.lat,
                      lng: CountryStatistic.lng
                    }"
                >
                  <l-icon
                      :icon-url="(
                          CountryStatistic.balanceIndexed > 80
                            ? iconUrlGreen
                            : (
                              CountryStatistic.balanceIndexed < 20
                                ? iconUrlRed
                                : iconUrlOrange
                            )
                      )"
                  />

                  <l-popup>
                    <h3>{{ CountryStatistic.title }} ({{ CountryStatistic.balanceIndexed }} %)</h3>

                    <br/>

                    <ul>
                      <li>
                        Indexovaných: <b>{{ CountryStatistic.indexed }}</b>
                      </li>
                      <li>
                        Neindexovaných: <b>{{ CountryStatistic.unindexed }}</b>
                      </li>
                      <li>
                        Celkovo: <b>{{ CountryStatistic.total }}</b>
                      </li>
                    </ul>

                    <br/>

                    <router-link
                        :to="'/zaznamy?Filters[Year]=' + Year.id + '&Filters[Country]=' + CountryStatistic.id"
                    >
                      zobraziť viac
                    </router-link>
                  </l-popup>
                </l-marker>
              </l-map>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-alert
          v-else
          type="error"
      >
        Nenašli sa žiadne mapy.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
  import { CRS } from 'leaflet'
  import { LIcon, LMap, LImageOverlay, LMarker, LPopup } from 'vue2-leaflet'

  import Years from '@/services/years'

  import markerRed from '@/assets/img/markers/red.svg'
  import markerOrange from '@/assets/img/markers/orange.svg'
  import markerGreen from '@/assets/img/markers/green.svg'

  export default {
    name: 'Maps',
    components: {
      LIcon,
      LMap,
      LImageOverlay,
      LMarker,
      LPopup
    },
    data() {
      return {
        loading: false,
        years: [],
        crs: CRS.Simple,
        iconUrlRed: markerRed,
        iconUrlOrange: markerOrange,
        iconUrlGreen: markerGreen
      }
    },
    metaInfo() {
      return {
        title: 'Mapy'
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Mapy',
              to: '/mapy'
            }
          ]
        })
      },
      loadData() {
        this.loading = true

        this.setBreadcrumbs()

        Years
          .all()
          .then((response) => {
            this.years = response.data.data.years
          })
          .catch((error) => {
            switch (error.response.status) {
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
